import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import { Box, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { ToastContainer } from "react-toastify";
import styled from "@emotion/styled";

const VisitContainer = styled.div`
.img-container {
    background-image: url(/img/alternators/react-test-img.jpeg);
    height: 60vh;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: -1;

    .vcard {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 60px 50px 8px 50px;
    background-color: #fff;

    display: grid;
    grid-template-columns: repeat(1fr);
    gap: 40px;

    div {
        display: grid;
        grid-template-columns: repeat(1fr);
        gap: 10px;
    }

    p {
        color: red;
        font-size: 0.8rem;
        font-weight: 500;
    }

    h2 {
        font-size: 2.3rem;
        color: #404040;
    }

    a {
        font-size: 0.8rem;
        color: #404040;
        font-weight: 500;
        text-underline-offset: 0.5rem;
    }
    }
}

.p-container {
    display: grid;
    grid-template-columns: repeat(1fr);
    gap: 30px;
    padding: 100px 0;

    p {
    color: #404040;
    font-size: 1.26rem;
    font-weight: 500;
    line-height: 160%;
    }
}

@media screen and (max-width: 1199px) {
    .img-container {
    .vcard {
        h2 {
        font-size: 2rem;
        color: #404040;
        }
    }
    }
}
`;

const FindContainer = styled.div`
.content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    .left {
    img {
        width: 100%;
    }
    }

    .icon-container {
    display: flex;
    align-items: center;
    gap: 10px;

    div {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        padding: 10px;

        display: grid;
        place-content: center;

        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    img {
        width: 9px;
    }
    }

    .fcard {
    display: flex;
    flex-direction: column;

    gap: 20px;
    }

    h2 {
    font-size: 2rem;
    color: #404040;
    }

    p {
    font-size: 1.2rem;
    line-height: 160%;
    color: #404040;
    }

    a {
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    color: red;
    }
}

@media screen and (max-width: 599px) {
    .content {
    grid-template-columns: repeat(1, 1fr);
    }
}
`;

export default function Alternators() {
return (
    <>
    <NavBar />
    <Visit />
    <Find />
    {/* CONTACT SECTION */}
    <Box sx={{ mt: 10 }}>
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a", textAlign: "center" }}
        fontSize={30}
        fontWeight={700}
        id="contact"
        >
        GET IN TOUCH
        </Typography>

        <Box>
        <Grid
            container
            sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
        >
            <ContactUs />
            <ToastContainer />
        </Grid>
        </Box>
    </Box>

    <div style={{ backgroundColor: "#EEEFF1" }}>
        <Container>
        <Footer />
        </Container>
    </div>

    <a
        href="https://wa.me/601158777451"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
    >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </a>
    </>
);
}

function Visit() {
return (
    <VisitContainer>
    <div className="img-container">
        <div className="vcard">
        <div>
            <p>CUMMINS GENERATOR TECHNOLOGIES</p>

            <h2>STAMFORD | AVK ALTERNATORS</h2>
        </div>

        <a href="https://www.stamford-avk.com" style={{ textTransform: "uppercase" }}>
            Visit stamford-avk.com
        </a>
        </div>
    </div>

    <Container maxWidth="lg">
        <div className="p-container">
        <p>
            We at GREENWATT primarily use Stamford as our alternator choice.
            STAMFORD | AvK provide the ultimate power solution for world-class
            expertise and 24/7 global lifecycle support, as the world's energy
            demands evolve. Our STAMFORD® and AvK® alternators are renowned
            throughout the power generation industry for dependability and
            pioneering innovation, from 7.5 - 11,200 kVA. Peace of mind is
            guaranteed in a range of applications including marine, telecoms,
            standby and critical protection, CHP, rail and prime power. We are
            powering tomorrow, together.
        </p>

        <p>
            Headquartered in England, UK, STAMFORD | AvK has complete global
            coverage, with regional offices and manufacturing facilities in
            Europe, Asia and India. Operating in over 116 countries, STAMFORD |
            AvK Authorised Parts and Service Dealers are on-hand for service,
            parts and support.
        </p>

        <p>
            Established in 1904, STAMFORD | AvK is the market company name for
            Cummins Generator Technologies, part of global power leader,
            Cummins. The STAMFORD | AvK business operates independently,
            committed to providing the versatile alternator brand value of
            STAMFORD® and AvK® alternators to a range of partners throughout the
            power generation industry.
        </p>
        </div>
    </Container>
    </VisitContainer>
);
}

function Find() {
return (
    <FindContainer>
    <Container maxWidth="lg">
        <div className="content">
        <div className="left">
            <img src="/img/alternators/react-test-img.jpeg" alt="react-test-img" />
        </div>

        <div className="fcard">
            <h2>Find STAMFORD | AvK online</h2>

            <p>
            Learn all about Cummins Generator Technologies world-renowned
            alternators. Find products, reference materials, and more.
            </p>

            <div className="icon-container">
            <a href="https://www.stamford-avk.com" target="_blank" rel="noreferrer">Visit stamford-avk.com</a>
            <div>
                <img src="/img/alternators/arrow-right.svg" alt="arrow-right" />
            </div>
            </div>
        </div>
        </div>
    </Container>
    </FindContainer>
);
}
