import React from "react";
import ReactDOM from "react-dom/client";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  // createHashRouter,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Alternators from "./Alternator/Alternators";
import Customize from "./Customize/customize";

import Customize2 from "./Customize/customize2";
import GS445W from "./components/GS445W";
import GS450W from "./components/GS450W";
import GS500W from "./components/GS500W";
import GS600W from "./components/GS600W";
import GS650W from "./components/GS650W";

import jsonData from "./json/data.json";
import Consultation from "./Services/Consultation";

import Transportation from "./Services/Transportation";
import AfterSales from "./Services/AfterSales";

import Success from "./components/Success";

const router = createBrowserRouter([
  {
    path: "/",  
    element: <App />,
  },
  {
    path: "/alternators",
    element: <Alternators />,
  },
  {
    path: "/diesel-generator",
    element: <Customize data={jsonData} />,
  },
  {
    path: "/solar-panel",
    element: <Customize2  />,
  },
  {
    path: "/solar-panel/gs445w",
    element: <GS445W  />,
  },
  {
    path: "/solar-panel/gs450w",
    element: <GS450W  />,
  },
  {
    path: "/solar-panel/gs500w",
    element: <GS500W  />,
  },
  {
    path: "/solar-panel/gs600w",
    element: <GS600W  />,
  },
  {
    path: "/solar-panel/gs650w",
    element: <GS650W  />,
  },
  {
    path: "/generator/open-type",
    element: <Customize data={jsonData} />,
  },
  {
    path: "/generator/silent-type",
    element: <Customize data={jsonData} />,
  },
  {
    path: "/generator/controller",
    element: <Customize data={jsonData} />,
  },
  {
    path: "/products-consultation",
    element: <Consultation />,
  },
  {
    path: "/delivery-and-installation",
    element: <Transportation />,
  },
  {
    path: "/after-sales",
    element: <AfterSales />,
  },
  {
    path: "/success",
    element: <Success />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
