import React, { useEffect, useRef } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// import { Pagination, Navigation } from "swiper/modules";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import {
Card,
CardContent,
CardMedia,
CardActions,
} from "@material-ui/core";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Link as Rlink } from "react-router-dom";

import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import ConstructionIcon from "@mui/icons-material/Construction";
import StoreIcon from "@mui/icons-material/Store";
import WavesIcon from "@mui/icons-material/Waves";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";

import "./Landing.css";
import { Typography } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { SiAdguard } from "react-icons/si";
import { SiLeaderprice } from "react-icons/si";
import { FaAward } from "react-icons/fa";

import { useLocation } from "react-router-dom";

// const Testimonial = styled.div`
// margin-bottom: 100px;

// .mySwiper {
//     padding: 25px 0;
//     margin: 25px 0;
// }

// /* Target the Swiper navigation arrows */
// .swiper-button-prev,
// .swiper-button-next {
//   opacity: 0.5; /* Set the desired opacity value */
// }

// /* Hover effect for the arrows (optional) */
// .swiper-button-prev:hover,
// .swiper-button-next:hover {
//   opacity: 1; /* Set opacity to full when hovered */
// }

// .inside-wrapper {
//     margin: 10px;

//     height: 445px;
//     width: 100%;

//     display: flex;
//     flex-direction: column;
//     align-items: start;
//     gap: 16px;

//     box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
//     0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
//     border-radius: 4px;
//     padding: 20px 30px;

//     .imgs {
//     display: grid;
//     grid-template-columns: 1fr;
//     gap: 10px;

//     .img1 {
//         width: auto;
//         height: 160px;
//     }

//     .img2 {
//         width: 100px;
//     }
//     }

//     h3 {
//     color: #404040;
//     }

//     p {
//     color: #404040;
//     }

//     .stars-cont {
//     display: flex;
//     gap: 4px;

//     img {
//         width: 22px;
//     }
//     }
// }
// `;

export const Gmap = styled.div`
margin: 100px 0;
display: grid;
grid-template-columns: repeat(1, 1fr);
gap: 20px;

.map {
}

.info-cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    text-align: left !important;
}

.location-info {
    text-transform: capitalize;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    text-align: left;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;

    color: #606060;

    svg {
    font-size: 2rem;
    }

    .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 20px;
    }
}

.abs-info {
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -10%);
    width: 76%;

    padding: 20px 40px;
    border-radius: 0.8rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;

    div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;

    color: #606060;
    }
}

@media screen and (max-width: 899px) {
    .info-cont {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    }
}
`;

export default function Landing() {
const location = useLocation();
const currentHash = location.hash;

const homeRef = useRef(null);
// const tcard = [
//     {
//     img1: "/img/testimonial/sentana-logo.jpeg",
//     img2: "/img/testimonial/rate.jpg",
//     p1: "We bought 2 x 1250 KW container gensets and the customer service is amazing! Delivery is fast too.",
//     h3: "PT. SENTANA AGRI NAGRAHA",
//     // p2: "Ibu Mira (Director)",
//     rating: { total: 5, rate: 5 },
//     },
//     {
//     img1: "/img/testimonial/phd.jpg",
//     img2: "/img/testimonial/rate.jpg",
//     p1: "The team at Greenwatt is very knowledgable and able to solve the alternator problem. They also recommended a cheaper but same quality alternative genset. Very pleased and highly recommend this company!",
//     h3: "PIZZA HUT DELIVERY BATAM",
//     // p2: "Pak Richan (Purchasing)",
//     rating: { total: 5, rate: 4.5 },
//     },
//     {
//     img1: "/img/testimonial/natra.jpg",
//     img2: "/img/testimonial/rate.jpg",
//     p1: "Very reliable genset company with quality products. We purchased a silent generator and it is ultra quiet, which is a must for our resort customers.",
//     h3: "PT. NATRA BINTAN RESORT",
//     // p2: "Pak Adi",
//     rating: { total: 5, rate: 3.5 },
//     },
//     // {
//     // img1: "/img/testimonial/user.jpg",
//     // img2: "/img/testimonial/rate.jpg",
//     // p1: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis dolor eius itaque laudantium quasi praesentium aut tempora alias in sequi.",
//     // h3: "Luna Jhon",
//     // p2: "UX Designer",
//     // rating: { total: 5, rate: 5 },
//     // },
//     {
//     img1: "/img/testimonial/KSI_logo.jpg",
//     img2: "/img/testimonial/rate.jpg",
//     p1: "Reliable genset partner with high level of after sales service. Prices are very competitive too.",
//     h3: "PT. KARYA SARI INDONESIA",
//     // p2: "Pak Suwandi",
//     rating: { total: 5, rate: 5 },
//     },
// ];

// function appendRatingStars(obj) {
//     const { total, rate } = obj.rating;
//     const rating = parseFloat(rate);
//     const fullStars = Math.floor(rating);
//     const hasHalfStar = rating % 1 !== 0;

//     let starsHTML = "";

//     for (let i = 0; i < fullStars; i++) {
//     starsHTML += `<img src="/img/testimonial/stars/full-star.png" alt="image"/>`; // Replace 'full-star' with your full star image URL
//     }

//     if (hasHalfStar) {
//     starsHTML += `<img src="/img/testimonial/stars/half-star.png" alt="image"/>`; // Replace 'half-star' with your half star image URL
//     }

//     const emptyStars = total - Math.ceil(rating);
//     for (let i = 0; i < emptyStars; i++) {
//     starsHTML += `<img src="/img/testimonial/stars/empty-star.png" alt="image"/>`; // Replace 'empty-star' with your empty star image URL
//     }

//     return starsHTML;
// }

useEffect(() => {
    setTimeout(() => {
    if (currentHash) {
        console.log(currentHash);
        let dom = document.querySelector(`[href="${currentHash}"]`);
        dom.click();
    }
    }, 100);
}, [currentHash]);

function handleNavClick(href) {
    console.log(href);
    setTimeout(() => {
    if (href) {
        console.log(href.split("#")[1]);
        let dom = document.querySelector(`[href="#${href.split("#")[1]}"]`);
        dom.click();
    }
    }, 100);
}

return (
    <Box ref={homeRef} sx={{ overflowX: "hidden" }} className="main-container">
    <NavBar handleNavClick={handleNavClick} />

    {/* <Box className="landing-content"> */}
    <Grid
        container
        sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        width: "100vw",
        height: "auto",
        backgroundImage: `url("./malaysia.png")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        }}
    >
        {/* HERO SECTION */}
        <Grid item xs={12} sm={12} md={4}>
        <Box sx={{ maxWidth: "100%", height: "auto" }}>
            {" "}
            {/* Add maxWidth and height properties */}
            <img src="../cummins-hero2.png" alt="cummins-hero" />{" "}
            {/* Add height and width properties */}
        </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
        <Box
            ml={{ xs: 0, md: 18 }}
            mb={6}
            sx={{ maxWidth: { xs: "100%", sm: "100%" } }}
        >
            <Typography
            variant="h2"
            sx={{
                textAlign: {
                xs: "center",
                md: "left",
                },
            }}
            >
            GREENWATT SOLUTIONS
            <br />
            <Typography variant="h4" fontStyle="italic">
                Powering you forward.
            </Typography>
            </Typography>
            <Box
            mt={4}
            mb={2}
            sx={{
                textAlign: {
                xs: "center",
                md: "left",
                },
            }}
            >
            <Button
                variant="contained"
                component="a"
                href="#contact"
                style={{
                fontWeight: "bold",
                backgroundColor: "#00A36C", // change to your desired color
                color: "#FFFFFF", // change to contrast with the background color
                }}
            >
                Contact Sales
            </Button>
            </Box>
        </Box>
        </Grid>
    </Grid>
    {/* </Box> */}

    {/* ABOUT US SECTION */}
    <div id="about">
        <Grid
        container
        sx={{
            backgroundColor: "#00A36C",
            padding: "20px 0",
        }}
        >
        <Grid item xs={12} md={4}>
            <FaAward size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            EXPERIENCED TEAM
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            We have experience <br /> operating in South-east
            Asia
            </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
            <SiAdguard size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            ACCIDENT-FREE
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            We have a zero accident track record <br /> since the start of our
            business operations
            </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
            <SiLeaderprice size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            AFFORDABLE
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            We offer competitive prices
            <br />
            without compromising on product quality
            </Typography>
        </Grid>
        </Grid>
    </div>

    {/* PRODUCTS SECTION */}
    <Box id="products">
        <Grid
        container
        spacing={0}
        sx={{
            // display: 'flex',
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            py: 10,
            px: 2,
        }}
        >
        <Grid item xs={12} sm={12} md={5} component="section">
            <Typography
            sx={{ textAlign: "start", color: "#00704a" }}
            fontSize={30}
            fontWeight={700}
            id="products"
            >
            PRODUCTS
            </Typography>

            <Typography
            variant="h6"
            component="h4"
            sx={{
                fontWeight: "500",
                paddingTop: 1,
                textAlign: "start",
            }}
            >
            Diesel Generator for Malaysia
            </Typography>

            <Typography
            variant="body1"
            sx={{
                maxWidth: "75%",
                textAlign: "start",
                mx: "0",
                paddingBottom: "10px",
            }}
            >
            All of our diesel generators are designed for arduous operation
            under the harshest conditions using tried, tested and trusted
            components.
            </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #ccc",
            }}
            >
            <CardContent>
                <IconButton>
                <ElectricMeterIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Diesel Generators
                </Typography>
            </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                boxShadow: "none",
                border: "1px solid black",
            }}
            >
            <CardContent>
                <IconButton>
                <ConstructionIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Telecommunication Applications
                </Typography>
            </CardContent>
            </Card>
        </Grid>

        <Grid
            item
            xs={12}
            sm={6}
            md={2}
            sx={{
            display: { xs: "none", sm: "flex" },
            }}
        >
            <Card
            square={true}
            elevation={0}
            sx={{
                boxShadow: "none !important",
                border: "none !important",

                minHeight: 180,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }}
            >
            <CardContent>
                <ArrowCircleRightRoundedIcon
                fontSize="large"
                color="secondary"
                />
            </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #ccc",
            }}
            >
            <CardContent>
                <IconButton>
                <StoreIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Data Center Applications
                </Typography>
            </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #ccc",
            }}
            >
            <CardContent>
                <IconButton>
                <WavesIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Marine Offshore Applications
                </Typography>
            </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #ccc",
            }}
            >
            <CardContent>
                <IconButton>
                <OilBarrelIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Mining Applications
                </Typography>
            </CardContent>
            </Card>
        </Grid>
        </Grid>
    </Box>

    {/* BANNER OF TRUSTED BRANDS */}
    <Box className="banner">
        <Typography
        variant="h6"
        component="h6"
        sx={{
            fontWeight: "600",
            padding: 1,
            px: 2,
            textAlign: "center",
            // fontStyle: 'italic',
            color: "#808080",
            display: { xs: "none", sm: "block" },
        }}
        >
        BRAND PARTNERS
        </Typography>
        <Grid
        container
        spacing={3}
        sx={{
            alignItems: "center",
            justifyContent: "center",
            display: { xs: "none", sm: "flex" },
        }}
        >
        <Grid item xs={12} sm={6} md={3}>
            <img
            src="../cummins-logo.png"
            alt="cummins logo"
            style={{
                width: "240px",
                height: "100px",
                objectFit: "contain",
                filter: "grayscale(100%)",
            }}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <img
            src="../perkins-logo.png"
            alt="perkins logo"
            style={{
                width: "240px",
                height: "100px",
                objectFit: "contain",
                filter: "grayscale(100%)",
            }}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <img
            src="../fawde-logo.png"
            alt="fawde logo"
            style={{
                width: "240px",
                height: "100px",
                objectFit: "contain",
                filter: "grayscale(100%)",
            }}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <img
            src="../mtu-logo.png"
            alt="MTU logo"
            style={{
                width: "240px",
                height: "100px",
                objectFit: "contain",
                filter: "grayscale(100%)",
            }}
            />
        </Grid>
        </Grid>
    </Box>

    {/* SERVICES SECTION */}
    <Box
        sx={{
        padding: "1rem",
        paddingTop: 10,
        paddingBottom: 10,
        }}
    >
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a" }}
        fontSize={30}
        fontWeight={700}
        id="services"
        >
        OUR SERVICES
        </Typography>
        <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{
            // delay: 0.5,
            x: { duration: 1 },
            default: { ease: "linear" },
        }}
        >
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                component="img"
                height="140"
                image="../../consultation.jpeg"
                alt="Consultation"
                />
                <CardContent style={{ minHeight: "150px" }}>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: 700 }}
                >
                    Products Consultation
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    We specialize in diesel genset consultation and installation
                    services in Johor and other Malaysia regions. Whether you need a small KVA generator or a
                    larger KVA generator for industrial use, we can help
                    you select the right generator to meet your needs.
                </Typography>
                </CardContent>

                <CardActions>
                <Rlink
                to={`/products-consultation`}
                >
                <Button
                    size="small"
                    color="primary"
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                >
                    More
                </Button>
                </Rlink>
                </CardActions>
            </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                component="img"
                height="140"
                image="../../transportation.jpeg"
                alt="Transportation"
                />
                <CardContent style={{ minHeight: "150px" }}>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: 700 }}
                >
                    Delivery & Installation
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    When you purchase a generator from Greenwatt, we use our
                    trusted delivery and service vehicles, so we can transport
                    generators and equipment to you no matter where you are.
                </Typography>
                </CardContent>

                <CardActions>
                <Rlink
                to={`/delivery-and-installation`}
                >
                <Button
                    size="small"
                    color="primary"
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                >
                    More
                </Button>
                </Rlink>
                </CardActions>
            </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                component="img"
                height="140"
                image="../../installation.jpg"
                alt="Installation"
                />
                <CardContent style={{ minHeight: "150px" }}>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: 700 }}
                >
                    After Sales Services
                </Typography>
                <Typography variant="body1" color="text.secondary">
                Experience unparalleled post-sales support with Greenwatt. From routine maintenance to expert repairs, we ensure your generators run efficiently long after purchase.
                </Typography>
                </CardContent>

                <CardActions>
                <Rlink
                to={`/after-sales`}
                >
                <Button
                    size="small"
                    color="primary"
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                >
                    More
                </Button>
                </Rlink>
                </CardActions>
            </Card>
            </Grid>
        </Grid>
        </motion.div>
    </Box>

    {/*TESTIMONIAL SECTION*/}
    {/* <Testimonial>
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a" }}
        fontSize={30}
        fontWeight={700}
        id="services"
        >
        TESTIMONIAL
        </Typography>

        <div className="slider-sec">
        <Swiper
            slidesPerView={3}
            centeredSlides={false}
            spaceBetween={30}
            grabCursor={true}
            pagination={{
            clickable: true,
            }}
            modules={[Pagination, Navigation]}
            navigation={true} // Enable navigation arrows
            breakpoints={{
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            499: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            599: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            899: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1199: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            }}
            className="mySwiper"
        >
            {tcard.map((obj, i) => (
            <SwiperSlide key={i}>
                <div className="inside-wrapper">
                <div className="imgs">
                    <img src={obj.img1} alt="img1" className="img1" />
                    <div
                    className="stars-cont"
                    dangerouslySetInnerHTML={{
                        __html: appendRatingStars(obj),
                    }}
                    />
                </div>

                <div>
                    <h3 style={{ textAlign: "left" }}>{obj.h3}</h3>
                    <h4 style={{ textAlign: "left" }}>{obj.p2}</h4>
                </div>

                <p style={{ textAlign: "left" }}>{obj.p1}</p>

                
                </div>
            </SwiperSlide>
            ))}
        </Swiper>
        </div>
    </Testimonial> */}

    {/* CONTACT SECTION */}
    <div id="contact">
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a" }}
        fontSize={30}
        fontWeight={700}
        id="contact"
        >
        GET IN TOUCH
        </Typography>

        <Box>
        <Grid
            container
            sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
        >
            <ContactUs />
            <ToastContainer />
        </Grid>
        </Box>
    </div>

    <Box>
        <Footer />
    </Box>
    </Box>
);
}
