import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import styled from "@emotion/styled";

const HeroSecDiv = styled.div`
    height: 25vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        url(/img/customize/solarImage.png);
    background-position: center;
    background-size: cover;
    display: grid;
    place-content: center;
    color: white;
    text-align: center;

    h2 {
        font-size: 2.4rem;
        font-weight: 600;
        text-transform: capitalize;
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    }

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        text-transform: capitalize;
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    }
`;

const ImageGallery = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three columns */
    gap: 20px;
    padding: 50px 0;

    .image-card {
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            height: 400px; /* Increased height */
            object-fit: cover;
            transition: transform 0.3s;
        }

        .title {
            padding: 10px;
            text-align: center;
            font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
            color: #333;
            font-weight: 600;
        }

        &:hover img {
            transform: scale(1.1);
        }
    }

    @media screen and (max-width: 899px) {
        grid-template-columns: repeat(2, 1fr); /* Adjusts to two columns */
    }

    @media screen and (max-width: 599px) {
        grid-template-columns: 1fr; /* Adjusts to one column */
    }
`;

const WhatsAppIcon = styled.a`
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 24px;
    color: #25D366;
    background: #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 1000;
`;

export default function Customize2() {
    const images = [
        { src: "/img/customize/560w.png", link: "/solar-panel/gs445w", title: "420-445Wp N-TOPCon Module" },
        { src: "/img/customize/560w.png", link: "/solar-panel/gs450w", title: "425-450Wp N-TOPCon Module" },
        { src: "/img/customize/560w.png", link: "/solar-panel/gs500w", title: "495-500Wp N-TOPCon Module" },
        { src: "/img/customize/560w.png", link: "/solar-panel/gs600w", title: "560-600Wp N-TOPCon Module" },
        { src: "/img/customize/560w.png", link: "/solar-panel/gs650w", title: "610-650Wp N-TOPCon Module" },
        // { src: "/img/customize/560w.png", link: "/solarpanel/page6", title: "620-670Wp N-TOPCon Module" }
    ];

    return (
        <>
            <NavBar />
            <HeroSecDiv>
                <div>
                    <h2>Solar Panels</h2>
                    <p>
                        <span>Products</span>
                        <span>{">"}</span>
                        <span>Solar Panels</span>
                    </p>
                </div>
            </HeroSecDiv>
            
            <Container>
                <ImageGallery>
                    {images.map((image, index) => (
                        <a key={index} href={image.link} className="image-card">
                            <img src={image.src} alt={`Solar panel ${index + 1}`} />
                            <div className="title">{image.title}</div>
                        </a>
                    ))}
                </ImageGallery>
            </Container>

            <Box sx={{ mt: 10 }}>
                <Typography
                    sx={{ paddingLeft: "20px", color: "#00704a", textAlign: "center" }}
                    fontSize={30}
                    fontWeight={700}
                    id="contact">
                    GET IN TOUCH
                </Typography>

                <Box sx={{}}>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        <ContactUs />
                    </Grid>
                </Box>
            </Box>

            <div style={{ backgroundColor: "#EEEFF1" }}>
                <Container>
                    <Footer />
                </Container>
            </div>

            <WhatsAppIcon
                href="https://wa.me/601158777451"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fa fa-whatsapp"></i>
            </WhatsAppIcon>
        </>
    );
}
