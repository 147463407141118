import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import styled from "@emotion/styled";

// Styled component for the hero section
const HeroSecDiv = styled.div`
    height: 25vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        url(${(props) => props.backgroundImage});
    background-position: center;
    background-size: cover;
    display: grid;
    place-content: center;
    color: white;
    text-align: center;

    h2 {
        font-size: 2.4rem;
        font-weight: 600;
        text-transform: capitalize;
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    }

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        text-transform: capitalize;
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    }
`;

const WhatsAppIcon = styled.a`
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 24px;
    color: #25D366;
    background: #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 1000;
`;

const BaseLayout = ({ children, heroContent, heroBackgroundImage }) => {
    return (
        <>
            <NavBar />
            {heroContent && heroBackgroundImage && (
                <HeroSecDiv backgroundImage={heroBackgroundImage}>
                    {heroContent}
                </HeroSecDiv>
            )}
            <main>{children}</main>
            <ContactUs />
            <Footer />
            <WhatsAppIcon
                href="https://wa.me/601158777451"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fa fa-whatsapp"></i>
            </WhatsAppIcon>
        </>
    );
};

export default BaseLayout;
