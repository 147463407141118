import React from "react";
import BaseLayout from "../components/BaseLayout";
import "./gs445w.css"; // Ensure this file includes your CSS

const GS500W = () => {
    return (
        <BaseLayout
            heroContent={
                <>
                    <h2>Solar Panels</h2>
                    <p>
                        <span>Products</span>
                        <span>&gt;</span>
                        <span>Solar Panels</span>
                    </p>
                </>
            }
            heroBackgroundImage="/img/customize/solarImage.png"
        >
            <div className="container flex flex-col items-center lg:mt-0 mt-[3rem] px-4">
                <div className="w-full">
                    {/* Flexbox Layout for Image and Details */}
                    <div className="flex flex-col md:flex-row gap-[3rem] items-start">
                        {/* Image Section */}
                        <div className="flex-shrink-0 detail1 w-full max-w-[300px] image-container">
                            <img
                                src="/img/customize/560w.png"
                                alt="560W"
                                className="img-small border border-gray-300 rounded-lg shadow-lg"
                            />
                        </div>

                        {/* Details Section */}
                        <div className="detail2 w-full">
                            <h1 className="text-baseblack text-[22px] font-bold mb-4">
                            GS-500W-182M/120TB
                            </h1>
                            <hr className="my-5 border-gray-300" />
                            <div className="text-details text-gray-700">
                                <p><b>Dimensions (mm):</b> 1196 x 1134 x 35/30</p>
                                <p><b>Cell Size (mm):</b> 182 x 91.87</p>
                                <p><b>Mono TOPCon:</b> 120 CELL</p>
                                <p><b>Power Output:</b> 495-500Wp</p>
                                <p><b>Max System Voltage:</b> 1500V DC</p>
                                <p><b>Max Efficiency:</b> 22.09%</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[5rem]">
                        <img
                            src="/img/customize/gs500wspec.png"
                            alt="Solar Panel Spec Sheet"
                            className="w-full h-auto border border-gray-300 rounded-lg shadow-lg"
                        />
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
};

export default GS500W;
