import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import Landing from "./Landing";
import theme from "./Theme/Theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Landing />
      </div>
      {/* WhatsApp icon */}
      <a
        href="https://wa.me/601158777451"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </ThemeProvider>
  );
}

export default App;
